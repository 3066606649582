import React from "react";
import { ReactComponent as TK } from "../assets/TK.svg";
import { ReactComponent as ADH } from "../assets/ADH.svg";
import LogoMedien from "../assets/logoMedienWhite.png";
import { ReactComponent as HULOGOweiss } from "../assets/HULOGOweiss.svg";

function Footer({ setQRModalOpen, setDatenschutzOpen, setImpressumOpen }) {
  return (
    <footer className="footer">
      <button className="download-button" onClick={() => setQRModalOpen(true)}>
        App herunterladen
      </button>
      <div className="sponsors-footer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "5%",
          }}
        >
          <div style={{ backgroundColor: "#2a4a4f" }}>
            <a
              href="https://www.hu-berlin.de/de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <HULOGOweiss />
            </a>
          </div>
          <div>
            <a
              href="https://www.tk.de/techniker"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TK />
            </a>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div id="sponsor1">
            <a
              href="https://www.adh.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <ADH />
            </a>
          </div>
          <div>
            <a href="https://mediencollege.berlin/">
              <img src={LogoMedien} style={{ height: 60 }} />
            </a>
          </div>
        </div>
      </div>
      <div className="links-container">
        <button
          onClick={() => setDatenschutzOpen(true)}
          className="footer-link"
        >
          Datenschutz
        </button>
        <button onClick={() => setImpressumOpen(true)} className="footer-link">
          Impressum
        </button>
      </div>
    </footer>
  );
}

export default Footer;
