import React from "react";
import { ReactComponent as Wordmark } from "../assets/DRHUHeader.svg";
import { ReactComponent as Logo } from "../assets/DRHULogo.svg";
import { ReactComponent as TK } from "../assets/TK.svg";
import { ReactComponent as HSS } from "../assets/HSS.svg";
import { ReactComponent as ADH } from "../assets/ADH.svg";
import { ReactComponent as HULOGO } from "../assets/HULOGO.svg";
import LogoMedien from "../assets/logoMedien.jpg";
import { Link } from "react-router-dom";

function Header() {
  return (
    <header className="header-container">
      <Link to="/drhu">
        <div className="logos-container">
          <Logo className="logo1" />
          <Wordmark className="wm1" />
        </div>
      </Link>
      <div className="sponsors-container">
        <div>
          <a
            href="https://www.hu-berlin.de/de"
            target="_blank"
            rel="noopener noreferrer"
          >
            <HULOGO />
          </a>
        </div>
        <div style={{ marginLeft: 20 }}>
          <a href="https://mediencollege.berlin/">
            <img style={{ height: 70, width: 120 }} src={LogoMedien} />
          </a>
        </div>
        <div id="sponsor1">
          <a
            href="https://www.adh.de/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ADH />
          </a>
        </div>
        <div>
          <a
            href="https://www.tk.de/techniker"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TK />
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
