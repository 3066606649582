import "./App.css";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { BrowserRouter as Router } from "react-router-dom";
import androidQR from "./assets/androidQRCode.png";
import iosQR from "./assets/iosQRCode.png";
import { Link } from "react-router-dom";
import GoogleBadge from "./assets/google-play-badge.png";
import AppleBadge from "./assets/AppleBadge.svg";
import batteries from "./assets/batteries.png";
import hu from "./assets/huScreenshot.png";
import prolog1 from "./assets/prolog1.png";
import prolog2 from "./assets/prolog2.png";
import prolog3 from "./assets/prolog3.png";
import prolog4 from "./assets/prolog4.png";
import prolog5 from "./assets/prolog5.png";

import Main from "./pages/Main";
import React, { useState } from "react";

function App() {
  const [isQRModalOpen, setQRModalOpen] = useState(false);
  const [datenschutzOpen, setDatenschutzOpen] = useState(false);
  const [impressumOpen, setImpressumOpen] = useState(false);
  const [habitsOpen, setHabitsOpen] = useState(false);
  const [comicOpen, setComicOpen] = useState(false);
  const [huOpen, setHuOpen] = useState(false);

  return (
    <Router>
      <div
        className="App"
        onClick={() => {
          // setDatenschutzOpen(false);
          // setImpressumOpen(false);
        }}
      >
        <Header />
        <div className="content">
          <Main
            setHuOpen={setHuOpen}
            setHabitsOpen={setHabitsOpen}
            setComicOpen={setComicOpen}
          />

          {habitsOpen && (
            <div
              id="qrModal"
              style={{ display: "flex" }}
              onClick={() => setHabitsOpen(false)}
            >
              <img
                style={{
                  maxWidth: "80%",
                  maxHeight: "80%",

                  borderRadius: 10,
                }}
                src={batteries}
              />
            </div>
          )}
          {huOpen && (
            <div
              id="qrModal"
              style={{ display: "flex" }}
              onClick={() => setHuOpen(false)}
            >
              <img
                style={{
                  // width: "100%",
                  maxWidth: "80%",
                  maxHeight: "80%",

                  borderRadius: 10,
                }}
                src={hu}
              />
            </div>
          )}
          {comicOpen && (
            <div
              id="qrModal"
              style={{ display: "flex" }}
              onClick={() => setComicOpen(false)}
            >
              <div className="modal-content-comic">
                {/* <span className="close-btn" onClick={() => setComicOpen(false)}>
                  x
                </span> */}
                <img src={prolog1} />
                <img src={prolog2} />
                <img src={prolog3} />
                <img src={prolog4} />
                <img src={prolog5} />
              </div>
            </div>
          )}
          {isQRModalOpen && (
            <div
              id="qrModal"
              style={{ display: "flex" }}
              onClick={() => setQRModalOpen(false)}
            >
              <div className="modal-content">
                <span
                  className="close-btn"
                  onClick={() => setQRModalOpen(false)}
                >
                  x
                </span>
                <div class="qrcodes">
                  <div class="half">
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "initial",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      to="https://play.google.com/store/apps/details?id=com.lotzofhackz.DrHU"
                    >
                      {/* <h3>Android</h3>
                       */}
                      <img
                        src={GoogleBadge}
                        style={{ width: 200, height: 80 }}
                      />
                      <img
                        style={{ height: 300, width: 300 }}
                        src={androidQR}
                        alt="Android Bild"
                      />
                    </Link>
                  </div>
                  <div class="half">
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "initial",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      to="https://apps.apple.com/de/app/dr-hu/id6445949504"
                    >
                      {/* <h3>iOS</h3>
                       */}
                      <img
                        src={AppleBadge}
                        style={{ width: 200, height: 70 }}
                      />
                      <img
                        style={{ height: 300, width: 300 }}
                        src={iosQR}
                        alt="QR Code für iOS"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          {datenschutzOpen && (
            <div
              id="qrModal"
              style={{ display: "flex" }}
              // onClick={() => setDatenschutzOpen(false)}
            >
              <div className="modal-content">
                <span
                  className="close-btn"
                  onClick={() => setDatenschutzOpen(false)}
                >
                  x
                </span>
                <h2> Datenschutz</h2>
                <h3>1. Name und Kontaktdaten der / des Verantwortlichen: </h3>
                <p>
                  Humboldt-Universität zu Berlin, vertreten durch die
                  Präsidentin Frau Prof. Dr.-Julia von Blumenthal <br />
                  Unter den Linden 6, 10099 Berlin <br /> Ansprechpartner*in im
                  Bereich ist Christoph Barth ZE Hochschulsport
                  <br /> Unter den Linden 6 10099 Berlin <br />
                  Telefon 030 2093 20199
                </p>
                <h3>
                  2. Welche Daten und für welche Zwecke werden die Daten
                  verarbeitet?
                </h3>
                <p>
                  Wir verarbeiten einen selbstgewählten Namen, um eine
                  persönliche Anspreche innerhalb der App zu gewährleisten.
                  Diese Information bleibt auf ihrem Gerät und wird nicht weiter
                  übertragen oder verarbeitet.
                </p>
                <h3>
                  3. Auf welcher Rechtsgrundlage werden die Daten verarbeitet?
                </h3>
                <p>
                  Die Rechtsgrundlage der Datenverarbeitung ergibt sich aus Art.
                  6 Abs.1 lit. A) DSGVO
                </p>
                <h3>4. Wer ist Empfänger Ihrer Daten? </h3>
                <p>Ihre Daten werden nicht an Dritte weitergeben.</p>
                <h3>
                  5. Werden Ihre Daten in ein Drittland oder an eine
                  internationale Organisation übermittelt?
                </h3>
                <p>
                  Es erfolgt keine Übermittlung der Daten an ein Drittland oder
                  eine internationale Organisation.
                </p>
                <h3>6. Wie lange werden Ihre Daten gespeichert?</h3>
                <p>
                  Ihre Daten werden lediglich lokal und nur bis zum Löschen der
                  Applikation selbst gespeichert.
                </p>
                <h3>
                  7. Gibt es für Sie eine Pflicht zur Bereitstellung von Daten?
                </h3>
                <p>
                  Alle Angaben stellen Sie freiwillig zur Verfügung, es besteht
                  keine Pflicht zur Bereitstellung von Daten. Falls die Daten
                  nicht vollständig sind, kann es sein, dass sich dies negativ
                  auf den Erfolg der App auswirken.
                </p>
                <h3>
                  8. Besteht eine automatisierte Entscheidungsfindung
                  einschließlich Profiling?
                </h3>
                <p>
                  Es wird keine automatisierte Entscheidungsfindung gem. Art 22
                  DSGVO durchgeführt.{" "}
                </p>
                <h3>9. Ihnen stehen folgende Rechte zu: </h3>
                <p>
                  Werden Ihre personenbezogenen Daten verarbeitet, so haben Sie
                  das Recht, Auskunft über die zu Ihrer Person gespeicherten
                  Daten zu erhalten (Art. 15 DSGVO). Sollten unrichtige
                  personenbezogene Daten verarbeitet werden, steht Ihnen ein
                  Recht auf Berichtigung zu (Art. 16 DSGVO).
                  <br />
                  Liegen die gesetzlichen Voraussetzungen vor, so können Sie die
                  Löschung oder Einschränkung der Verarbeitung verlangen (Art.
                  17 und 18 DSGVO). Sie haben unter bestimmten Voraussetzungen
                  ein Recht auf Übertragung der Sie betreffenden
                  personenbezogenen Daten, die Sie uns bereitgestellt haben, in
                  einem strukturierten, gängigen und maschinenlesbaren Format,
                  wenn die Verarbeitung auf einer Einwilligung oder einem
                  Vertrag beruht und mit Hilfe automatisierter Verfahren erfolgt
                  (Art. 20 DSGVO).
                  <br />
                  Soweit die Verarbeitung aufgrund Ihrer Einwilligung geschieht
                  (Art. 6 Abs. 1 a bzw. Art. 9 Abs. 1 a DSGVO), steht Ihnen das
                  Recht zu, die Einwilligung jederzeit und ohne Angabe von
                  Gründen für die Zukunft zu widerrufen. Der Widerruf berührt
                  hierbei nicht die Rechtmäßigkeit der Verarbeitung vor dem
                  Widerruf der Einwilligung. Zur Löschung der Daten können sie
                  die App jederzeit von ihrem Gerät entfernen.
                </p>
                <p>
                  Sofern Sie der Ansicht sind, dass die Verarbeitung der sie
                  betreffenden personenbezogenen Daten gegen
                  Datenschutzbestimmungen verstößt, haben Sie das Recht sich an
                  eine Datenschutzaufsichtsbehörde zu wenden, zum Beispiel an
                  die Landesbeauftragte für Datenschutz und Informationsfreiheit
                  Berlin zu (Art 77 DSGVO). Berliner Beauftragte für Datenschutz
                  und Informationsfreiheit, Alt-Moabit 59-61, 10555 Berlin.{" "}
                </p>
                <h3>
                  10. Sie können sich ferner an die Behördlichen
                  Datenschutzbeauftragten der HumboldtUniversität zu Berlin
                  wenden.{" "}
                </h3>
                <p>
                  Tel: +49 (30) 2093-20020, E-Mail: datenschutz@uv.hu-berlin.de
                </p>
              </div>
            </div>
          )}
          {impressumOpen && (
            <div
              id="qrModal"
              style={{ display: "flex" }}
              // onClick={() => setImpressumOpen(false)}
            >
              <div className="modal-content">
                <span
                  className="close-btn"
                  onClick={() => setImpressumOpen(false)}
                >
                  x
                </span>
                <h2> Impressum</h2>
                <div style={{ textAlign: "right" }}>
                  <p>Humboldt-Universität zu Berlin </p>
                  <p>Unter den Linden 6</p>
                  <p>10099 Berlin</p>
                  <p>Telefon: +49 30 2093–0 (Zentrale)</p>
                  <p>Website: www.hu-berlin.de / www.hu.berlin</p>
                  <p>Hochschulsport</p>

                  <p>www.hochschulsport.hu-berlin.de</p>

                  <p>Martina Rost, Direktorin</p>

                  <p>Hannoversche Straße 25</p>
                  <p>10115 Berlin</p>

                  <p>hochschulsport@hu-berlin.de</p>
                  <p>Telefon: 030 2093-20180</p>
                  <p>
                    Die Humboldt-Universität zu Berlin ist eine Körperschaft des
                    öffentlichen Rechts. Sie wird durch ihre Präsidentin, Frau
                    Prof. Dr. Julia von Blumenthal, Unter den Linden 6, 10099
                    Berlin, vertreten.
                  </p>
                  <p>
                    Die Umsatzsteuer-Identifikationsnummer der
                    Humboldt-Universität zu Berlin lautet: DE 137176824
                  </p>
                  <p>
                    Verantwortlich für die journalistisch-redaktionell
                    gestalteten Angebote der Webseiten der Humboldt-Universität
                    zu Berlin ist Frau Birgit Mangelsdorf, Abteilungsleiterin
                    Abteilung Kommunikation, Marketing und
                    Veranstaltungsmanagement (VIII).
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        <Footer
          setQRModalOpen={setQRModalOpen}
          setDatenschutzOpen={setDatenschutzOpen}
          setImpressumOpen={setImpressumOpen}
        />
        <div className="placeholder" />
      </div>
    </Router>
  );
}

export default App;
