import React from "react";
import { ReactComponent as WelcomeDoc } from "../assets/Illustration1.svg";
import Illustration2 from "../assets/img2.webp";
import Illustration3 from "../assets/img3.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Features from "../components/Features";
import GoogleBadge from "../assets/google-play-badge.png";
import AppleBadge from "../assets/AppleBadge.svg";

const Main = ({ setHabitsOpen, setComicOpen, setHuOpen }) => {
  // function SampleNextArrow(props) {
  //   const { className, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         width: "50px",
  //         height: "50px",
  //         background: "#2a4a4f",
  //         borderRadius: "420px",
  //         zIndex: 420,
  //       }} // Nur zu Demonstrationszwecken, hier kannst du deinen eigenen Stil definieren
  //       onClick={onClick}
  //     />
  //   );
  // }

  // function SamplePrevArrow(props) {
  //   const { className, onClick } = props;
  //   return (
  //     <div
  //       className={className}
  //       style={{
  //         width: "50px",
  //         height: "50px",
  //         background: "#2a4a4f",
  //         borderRadius: "420px",
  //         zIndex: 420,
  //       }} // Nur zu Demonstrationszwecken
  //       onClick={onClick}
  //     />
  //   );
  // }

  const settings = {
    responsive: [
      {
        breakpoint: 768, // Mobile Ansicht unter dieser Breite
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // swipe: false,
          infinite: true, // Deaktiviert die Endlosschleife
          arrows: false, // Versteckt Pfeile
          dots: false,
          autoplay: true,
          autoplaySpeed: 3000,
        },
      },
    ],
    dots: false,
    infinite: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    // speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="slide">
      <div className="image-container">
        <Slider {...settings} className="my-custom-slider">
          <div className="image-wrapper">
            <WelcomeDoc id="welcome-doc" />
          </div>
          <div className="image-wrapper">
            <img src={Illustration2} className="img2" />
          </div>
          <div className="image-wrapper">
            <img src={Illustration3} className="img3" />
          </div>
        </Slider>
      </div>
      <div className="text-wrapper">
        <h2 style={{ marginTop: 0 }}>Zähme deinen Schweinehund! </h2>
        <Features
          open={setHabitsOpen}
          icon="gluehbirne"
          title="Gesunde Gewohnheiten"
          content="Hol dir die Kontrolle über deine Gesundheit und erreiche endlich deine selbstgesteckten Ziele."
        />
        <Features
          open={setComicOpen}
          icon="comic"
          title="Erlebe die Story von Dr. HU"
          content="Spiel die Geschichte des Wissenschaftsgenies Dr. HU frei und erfahre, wie er seinen eigenen Schweinehund in den Griff bekommt."
        />
        <Features
          open={setHuOpen}
          icon="lvlup"
          title="Gesundheit an der HU"
          content="Finde die richtigen Angebote rund um die Themen Bewegung, Ernährung, mentale Gesundheit und Beratung an der Humboldt Uni auf einen Klick."
        />
      </div>
      <div className="floating-buttons">
        <button
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.lotzofhackz.DrHU"
            )
          }
          className="float-btn"
        >
          <img style={{ width: 160, height: 65 }} src={GoogleBadge} />
        </button>
        <button
          onClick={() =>
            window.open("https://apps.apple.com/de/app/dr-hu/id6445949504")
          }
          className="float-btn"
        >
          <img style={{ width: 160, height: 65 }} src={AppleBadge} />
        </button>
      </div>
    </div>
  );
};

export default Main;
