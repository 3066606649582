import React from "react";

const Features = ({ icon, title, content, open }) => (
  <div
    className="features-container"
    onClick={() => open(true)}
    style={{ cursor: "pointer" }}
  >
    <div className="icon-container">
      {icon === "lvlup" ? (
        <svg width="86" height="86" viewBox="0 0 86.857 86.857">
          <g
            id="Master_Icon"
            data-name="Master Icon"
            transform="translate(0.217 -0.078)"
          >
            <path
              id="Farbe"
              d="M86.857,43.428A43.428,43.428,0,1,1,43.428,0,43.428,43.428,0,0,1,86.857,43.428"
              transform="translate(-0.217 0.078)"
              fill="#63929c"
            />
            <g
              id="LVL_Up"
              data-name="LVL Up"
              transform="translate(9.411 9.794)"
            >
              <path
                id="Path_7044"
                data-name="Path 7044"
                d="M25.941,0A25.941,25.941,0,1,1,0,25.941,25.941,25.941,0,0,1,25.941,0Z"
                transform="translate(7.773 7.929)"
                fill="#2a4a4f"
                opacity="0.5"
              />
              <path
                id="Path_7040"
                data-name="Path 7040"
                d="M-6.669,2838.2h-.01a3.963,3.963,0,0,1-3.959-3.958v-1.282l-.846-.139a27.975,27.975,0,0,1-5.249-1.395l-.8-.3-.633,1.1a3.975,3.975,0,0,1-3.433,1.981,3.95,3.95,0,0,1-1.973-.531,3.938,3.938,0,0,1-1.855-2.409,3.929,3.929,0,0,1,.4-3l.627-1.087-.657-.544a29.127,29.127,0,0,1-3.845-3.845l-.544-.657-1.1.633a3.96,3.96,0,0,1-1.973.53,3.977,3.977,0,0,1-3.433-1.98l-.009-.016a3.928,3.928,0,0,1-.393-3,3.925,3.925,0,0,1,1.846-2.4l1.1-.632-.295-.8a29.069,29.069,0,0,1-1.4-5.257l-.142-.842h-1.28a3.962,3.962,0,0,1-3.959-3.957,3.968,3.968,0,0,1,3.959-3.969h1.283l.139-.846a27.842,27.842,0,0,1,1.394-5.24l.3-.8-1.1-.633a3.93,3.93,0,0,1-1.846-2.4,3.933,3.933,0,0,1,.394-3,3.986,3.986,0,0,1,3.441-1.993,3.963,3.963,0,0,1,1.973.529l1.1.633.544-.657a29.186,29.186,0,0,1,3.844-3.843l.658-.545-.635-1.1a3.938,3.938,0,0,1-.4-3.006,3.935,3.935,0,0,1,1.846-2.4,3.977,3.977,0,0,1,1.98-.534,3.972,3.972,0,0,1,3.433,1.981l.592,1.028.039.067h0c1.5,2.936,1.681,4.684-.84,6.191a23.729,23.729,0,0,0-11.941,20.54,23.669,23.669,0,0,0,23.642,23.643A23.67,23.67,0,0,0,16.958,2804.4a23.687,23.687,0,0,0-12.2-20.683c-2.886-1.7-1.272-4.99-.585-6.045h0l.625-1.087a3.961,3.961,0,0,1,7.258.949,3.927,3.927,0,0,1-.391,3l-.632,1.1.657.544a29.127,29.127,0,0,1,3.844,3.843l.546.659,1.094-.633a3.961,3.961,0,1,1,3.963,6.859l-1.094.63.295.8a29.21,29.21,0,0,1,1.4,5.257l.14.842h1.28a3.958,3.958,0,1,1,0,7.916l-1.264,0-.139.849a28.027,28.027,0,0,1-1.395,5.249l-.3.8,1.1.632a3.961,3.961,0,0,1,1.45,5.408,3.951,3.951,0,0,1-5.412,1.464l-1.1-.632-.544.656a29.218,29.218,0,0,1-3.845,3.845l-.657.542.633,1.1a3.966,3.966,0,0,1-6.871,3.961l-.63-1.094-.8.295a29.229,29.229,0,0,1-5.256,1.4l-.843.14v1.282A3.962,3.962,0,0,1-6.669,2838.2Z"
                transform="translate(40.485 -2770.781)"
                fill="#2a4a4f"
                stroke="rgba(0,0,0,0)"
              />
              <path
                id="Path_7041"
                data-name="Path 7041"
                d="M-32.8,2795.33a1.622,1.622,0,0,1-1.621-1.622v-3.244h-3.771a1.622,1.622,0,0,1-1.429-2.39l8.636-16.035a1.621,1.621,0,0,1,2.854,0l8.636,16.035a1.621,1.621,0,0,1-1.427,2.39H-24.69v3.244a1.622,1.622,0,0,1-1.621,1.622Z"
                transform="translate(63.267 -2771.187)"
                fill="#fff"
              />
              <path
                id="Path_7042"
                data-name="Path 7042"
                d="M1.621,0H8.107A1.621,1.621,0,0,1,9.728,1.621V6.485A1.621,1.621,0,0,1,8.107,8.107H1.621A1.621,1.621,0,0,1,0,6.485V1.621A1.621,1.621,0,0,1,1.621,0Z"
                transform="translate(28.85 27.385)"
                fill="#fff"
              />
              <path
                id="Path_7043"
                data-name="Path 7043"
                d="M1.621,0H8.107A1.621,1.621,0,0,1,9.728,1.621V6.485A1.621,1.621,0,0,1,8.107,8.107H1.621A1.621,1.621,0,0,1,0,6.485V1.621A1.621,1.621,0,0,1,1.621,0Z"
                transform="translate(28.85 38.734)"
                fill="#fff"
              />
            </g>
          </g>
        </svg>
      ) : icon == "comic" ? (
        <svg width="86" height="86" viewBox="0 0 86.857 86.857">
          <g
            id="Master_Icon"
            data-name="Master Icon"
            transform="translate(0.217 -0.078)"
          >
            <path
              id="Farbe"
              d="M86.857,43.428A43.428,43.428,0,1,1,43.428,0,43.428,43.428,0,0,1,86.857,43.428"
              transform="translate(-0.217 0.078)"
              fill="#63929c"
            />
            <g
              id="Comic_2.0"
              data-name="Comic 2.0"
              transform="translate(10.862 6.507)"
            >
              <path
                id="Path_6671"
                data-name="Path 6671"
                d="M50.22,246.466s-1.219-6.908-5.183-6.395-1.969,9.716,2.2,10.879,2.983-4.484,2.983-4.484"
                transform="translate(-28.442 -190.748)"
                fill="#2a4a4f"
              />
              <path
                id="Path_6672"
                data-name="Path 6672"
                d="M58.36,290.667c-.1-.183-.2-.368-.306-.558.094.188.2.375.306.558"
                transform="translate(-38.821 -225.018)"
                fill="#2a4a4f"
              />
              <path
                id="Path_6673"
                data-name="Path 6673"
                d="M184.158,227.484l3.306-1.358c.75-8.192-4.007-19.028-4.007-19.028v0a12.51,12.51,0,0,0-1.176,2.179.025.025,0,0,1,0,.008c-1.441,3.315-3.115,10.088,1.881,18.2"
                transform="translate(-122.844 -168.196)"
                fill="#2a4a4f"
                opacity="0.5"
              />
              <path
                id="Path_6674"
                data-name="Path 6674"
                d="M94.285,200.057h-.008a13.537,13.537,0,0,0-2.782.277l7.42-15.639h0c5.925,2.6,12.825-.974,12.825-.974s-.939,2.516.451,7.942c1.336,5.215-.728,3.166-5.437.733a16.326,16.326,0,0,1,1.253,4.122,16.076,16.076,0,0,1,.18,3.653,91.422,91.422,0,0,1-12.267,0c-.963-.06-1.562-.112-1.637-.118"
                transform="translate(-61.712 -152.194)"
                fill="#2a4a4f"
              />
              <path
                id="Path_6675"
                data-name="Path 6675"
                d="M18.945,213.376s2.033-20.137.057-25.09h0c9.353-4.83,11.281-11.988,11.281-11.988s6.654,7.117,10.644,8.386,4.923,2.234-7.16,4.456c-.444.082-.873.155-1.291.223a21.775,21.775,0,0,1,1.637,3.562,19.58,19.58,0,0,1,1.1,5.533,15.39,15.39,0,0,1,1.709-1.236,14.944,14.944,0,0,1,4.909-1.969,13.541,13.541,0,0,1,2.782-.277h.008c.074.006.674.058,1.637.118a91.343,91.343,0,0,0,12.267,0,38.258,38.258,0,0,0,3.908-.5l.456.412.556.5a3.7,3.7,0,0,1,2.575-.83c1.312-.031,3.925-3.882,5.461-6.468a.034.034,0,0,0,0-.008,20.032,20.032,0,0,0,1.175-2.179v0s3.7,8.43,4.057,16.039c.02.392.029.784.029,1.17,0,.617-.025,1.224-.079,1.818L73.364,206.4l-35.81,14.71a33.93,33.93,0,0,1-5.453-7.448c-3.2.344-10.365.938-13.156-.285"
                transform="translate(-12.05 -147.112)"
                fill="#2a4a4f"
                opacity="0.5"
              />
              <path
                id="Path_6676"
                data-name="Path 6676"
                d="M188.34,237.8c.43,2.025,1.121,3.595,1.544,3.505s.417-1.8-.013-3.83-1.121-3.6-1.544-3.505-.417,1.8.013,3.83"
                transform="translate(-127.78 -186.585)"
                fill="#2a4a4f"
              />
              <path
                id="Path_6677"
                data-name="Path 6677"
                d="M157.752,241a5.555,5.555,0,0,1,.03-4.918l-.556-.5a5.688,5.688,0,0,0,.525,5.421"
                transform="translate(-106.386 -187.691)"
                fill="#2a4a4f"
              />
              <path
                id="Path_6678"
                data-name="Path 6678"
                d="M160.027,275.344s.4,4.765-2.413.726Z"
                transform="translate(-106.971 -214.911)"
                fill="#fff"
              />
              <path
                id="Path_6679"
                data-name="Path 6679"
                d="M170.863,265s1.995,1.994.276,5.17a.858.858,0,0,1-1.488.038,8.192,8.192,0,0,0-2.043-2.286l2.789-1.616Z"
                transform="translate(-113.811 -207.832)"
                fill="#fff"
              />
              <path
                id="Path_6680"
                data-name="Path 6680"
                d="M130.941,267.163c0-.134-.011-2.73,3.552-2.623,3.656.113,6.792.547,11.917-3.3a38.345,38.345,0,0,0,6.978-2.134c0-.386-.009-.778-.029-1.171a43.374,43.374,0,0,1-7.618,2.229,11.394,11.394,0,0,1-9.6,3.466c-5.881-.72-5.232,3.355-5.2,3.53"
                transform="translate(-88.689 -202.996)"
                fill="#2a4a4f"
              />
              <path
                id="Path_6681"
                data-name="Path 6681"
                d="M81.891,257.033c1.915,3.076,5.508,4.3,8.027,2.732s3.008-5.332,1.093-8.408-5.508-4.3-8.027-2.732-3.008,5.332-1.093,8.408"
                transform="translate(-54.337 -196.154)"
                fill="#fff"
              />
              <path
                id="Path_6716"
                data-name="Path 6716"
                d="M321.8,2773.584c0-8.058,9.437-14.592,21.078-14.592s21.077,6.534,21.077,14.592a10.553,10.553,0,0,1-1,4.463l6.965,6.568a.811.811,0,0,1-.557,1.4.779.779,0,0,1-.209-.028l-10.424-2.784c-3.863,3.047-9.531,4.972-15.848,4.972C331.236,2788.176,321.8,2781.644,321.8,2773.584Z"
                transform="translate(-309.101 -2758.992)"
                fill="#fff"
              />
              <path
                id="Path_6715"
                data-name="Path 6715"
                d="M22.315,13.859v5.887l-.861,9.907H14.561L13.7,19.746V13.859Zm-4.307,17.05a4.777,4.777,0,0,1,3.1.9,3.108,3.108,0,0,1,1.095,2.549A3.108,3.108,0,0,1,21.112,36.9a4.777,4.777,0,0,1-3.1.9,4.79,4.79,0,0,1-3.087-.9,3.086,3.086,0,0,1-1.113-2.549,3.086,3.086,0,0,1,1.113-2.549A4.791,4.791,0,0,1,18.007,30.909ZM35.668,13.5a16.906,16.906,0,0,1,5.169.736,7.9,7.9,0,0,1,3.643,2.279,5.789,5.789,0,0,1,1.346,3.948,6.562,6.562,0,0,1-.933,3.7A5.654,5.654,0,0,1,42.4,26.243a11.972,11.972,0,0,1-3.464.9l-.61,2.584H31.36L31.4,24.2a13.424,13.424,0,0,0,3.248-.431,3.6,3.6,0,0,0,1.705-.933,1.869,1.869,0,0,0,.5-1.256,2,2,0,0,0-.179-.9,1.159,1.159,0,0,0-.556-.538,2.311,2.311,0,0,0-.987-.179,1.821,1.821,0,0,0-1.526.664,4.3,4.3,0,0,0-.7,2.1l-8.112-2.4a9.055,9.055,0,0,1,1.669-3.446,8.155,8.155,0,0,1,3.428-2.459A15.384,15.384,0,0,1,35.668,13.5Zm-.646,17.409a4.777,4.777,0,0,1,3.1.9,3.108,3.108,0,0,1,1.095,2.549A3.108,3.108,0,0,1,38.126,36.9a4.777,4.777,0,0,1-3.1.9,4.715,4.715,0,0,1-3.069-.9,3.108,3.108,0,0,1-1.095-2.549,3.108,3.108,0,0,1,1.095-2.549A4.715,4.715,0,0,1,35.022,30.909Z"
                transform="translate(-13.7 4.036)"
                fill="#2a4a4f"
              />
            </g>
          </g>
        </svg>
      ) : (
        <svg width="86" height="86" viewBox="0 0 86.857 86.857">
          <g
            id="Master_Icon"
            data-name="Master Icon"
            transform="translate(0.217 -0.078)"
          >
            <path
              id="Farbe"
              d="M86.857,43.428A43.428,43.428,0,1,1,43.428,0,43.428,43.428,0,0,1,86.857,43.428"
              transform="translate(-0.217 0.078)"
              fill="#63929c"
            />
            <g id="Gluehbirne" transform="translate(21.289 14.894)">
              <path
                id="Path_6279"
                data-name="Path 6279"
                d="M51,186.391v8.936a7.031,7.031,0,0,0,5.226,6.773,5.723,5.723,0,0,0,10.7,0,7.031,7.031,0,0,0,5.226-6.773v-8.813A21.948,21.948,0,0,1,51,186.391"
                transform="translate(-39.763 -145.324)"
                fill="#2a4a4f"
              />
              <g id="Group_1942" data-name="Group 1942" opacity="0.5">
                <path
                  id="Path_6280"
                  data-name="Path 6280"
                  d="M21.923,0A21.926,21.926,0,1,0,43.846,21.923,21.923,21.923,0,0,0,21.923,0"
                  fill="#2a4a4f"
                />
              </g>
              <path
                id="Path_6281"
                data-name="Path 6281"
                d="M69.5,75.362a3.207,3.207,0,0,1-2.151-.655,2.212,2.212,0,0,1-.733-1.788,2.257,2.257,0,0,1,.731-1.824,3.206,3.206,0,0,1,2.151-.655,3.264,3.264,0,0,1,2.187.655,2.259,2.259,0,0,1,.73,1.824,2.215,2.215,0,0,1-.73,1.788,3.269,3.269,0,0,1-2.187.655m.093-8a2.307,2.307,0,0,1-2.307-2.307V64.3a1.6,1.6,0,0,1,1.34-1.574,12.345,12.345,0,0,0,1.9-.461,4.4,4.4,0,0,0,2.106-1.311A3.075,3.075,0,0,0,73.287,59a3.251,3.251,0,0,0-.418-1.683,2.658,2.658,0,0,0-1.261-1.085,4.941,4.941,0,0,0-1.966-.333A3.809,3.809,0,0,0,66.8,56.98a3.85,3.85,0,0,0-.524.671A2.327,2.327,0,0,1,64.3,58.769a2.273,2.273,0,0,1-.748-.131l-.292-.1a2.281,2.281,0,0,1-1.45-1.522,2.2,2.2,0,0,1,.368-2l.02-.025a7.248,7.248,0,0,1,2.992-2.181,12.883,12.883,0,0,1,4.893-.8,13.757,13.757,0,0,1,4.578.708,6.709,6.709,0,0,1,3.058,2.064A5.252,5.252,0,0,1,78.8,58.167a5.963,5.963,0,0,1-.828,3.324,5.218,5.218,0,0,1-2.257,1.9,11.118,11.118,0,0,1-3.277.875l-.427.048-.117,1.007A2.307,2.307,0,0,1,69.6,67.359"
                transform="translate(-48.112 -40.543)"
                fill="#fff"
              />
            </g>
          </g>
        </svg>
      )}
    </div>
    <div className="text-container">
      <h3>{title}</h3>
      <p>{content}</p>
    </div>
  </div>
);

export default Features;
